<template>
<div>
    <main-layout></main-layout>
    <section class="wrapper">
        <div class="container-promotion">
            <h2 class="text-center">{{ $t("promotion.redeemPromotion") }}</h2>
            <div class="point-info">
                <h6>{{ $t("membership.availablePoints")}} : &nbsp;</h6>
                <h6 class="point-available">{{customerInfo.availablePoints}}</h6>
            </div>
            <hr>
            <h5 class="mt-5 text-center" v-if="promotion.allPromotion.length === 0">
                {{ $t("promotion.empty") }}
            </h5>
            <div class="list-promotion-wrapper">
                <div class="row">
                    <div v-for="promo in promotion.allPromotion" :key="'promotion-list-' + promo.id" class=" col-lg-4 col-md-6 col-sx-12">
                        <div class=" promotion-item">
                            <div class="container-image">
                            <b-card-img :src="getImageSource(promo)" alt="Promotion Image" @error="handleImageError"></b-card-img>
                            </div>
                            <div class="info-wrapper">
                                <div class="promo-info">
                                    <h5>{{ promo.name }}</h5>
                                    <p>{{ $t('promotion.requiredPoints') + ': ' + promo.point }}</p>
                                </div>

                                <button class="detail-btn button--outline" @click="explore(promo.id)">
                                    <i class="fas fa-angle-double-right"></i>
                                    <span>{{ $t('button.explore') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <loader v-show="loading"></loader>
</div>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import Constants from '@/common/constant';
import {
    mapGetters,
    mapState
} from "vuex";
import Loader from "@/components/Loader.vue";
export default {
    name: "RedeemPromotion",
    components: {
        MainLayout,
        Loader,
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            loading: false,
        };
    },
    computed: {
        ...mapState(['promotion']),
        ...mapGetters({
            authDataGet: "auth/authDataGet",
            userOrder: "auth/userOrder",
            deliveryDataGet: "deliveryDataGet",
            customerInfo: "customer/customerInfo"
        })
    },
    async mounted() {
        this.loading = true;
        await this.$store.dispatch('promotion/fetchAllPromotion');
        await this.$store.dispatch('customer/fetchCustomerData', {
            id: this.authDataGet.id,
            brandInfo: this.brandInfo
        });
        this.loading = false;
    },
    methods: {
        getImageSource(promo) {
            if (promo && promo?.image?.full_original_url) {
                return promo.image.full_original_url;
            }
            return Constants.TEMP_IMAGE;
        },
        handleImageError() {
            // Handle image loading errors
        },
        back() {
            this.$router.back();
        },
        explore(id) {
            console.log(id);
            this.$router.push({
                name: 'RedeemPromotionDetail',
                params: {
                    promotionId: id
                }
            });
        }
    },
};
</script>

  
<style lang="scss" scoped>
  </style>
